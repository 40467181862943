<template>
    <div>
        <b-row>
            <b-col>
                <b-card no-body>
                    <b-card-header>
                        <strong>Trainee Transcript</strong>
                    </b-card-header>
                    <b-card-body>
                        <b-row>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-lastname" label="Trainee's Last Name:">
                                    <b-input-group size="sm">
                                        <b-input id="search-lastname" placeholder="Last Name" v-model="criteria.lastname"/>
                                        <b-input-group-addon>
                                            <b-button @click="criteria.lastname = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col cols="md-3">
                                <b-form-group label-for="search-ubcid" label="Trainee's UBC ID:">
                                    <b-input-group size="sm">
                                        <b-input id="search-ubcid"
                                                 v-model="criteria.ubcId"
                                                 placeholder="UBC ID"  />
<!--                                        <ubc-id id="search-ubcid" v-model="criteria.ubcId" input-class="form-control form-control-sm"/>-->
                                        <b-input-group-addon>
                                            <b-button @click="criteria.ubcId = null"><font-awesome-icon icon="times"/></b-button>
                                        </b-input-group-addon>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card-body>
                    <b-card-footer class="clearfix">
                        <b-button-group size="sm">
                            <b-button variant="primary" @click="doSearch"><b-spinner v-if="searching" small type="grow"></b-spinner>Search</b-button>
                        </b-button-group>
                    </b-card-footer>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-row>
                        <b-col cols="4">
                            <b-pagination :total-rows="searchResults.length" :per-page="perPage" v-model="currentPage"/>
                        </b-col>
                        <b-col cols="8">
                            <b-form-group :label="'Records Per Page: ' + perPage">
                                <b-input type="range" v-model="perPage" min="10" max="100" step="10"></b-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <b-row>
                        Page {{ currentPage }} of {{ pages }}
                    </b-row>
                    <b-row>
                        <b-table small striped hover bordered no-local-sorting stacked="md" head-variant="dark"
                                    ref="searchResultsTable"
                                    selectable
                                    select-mode="single"
                                    selected-variant="warning"
                                    @row-selected="onRowSelected"
                                    :items="searchResults"
                                    :fields="tableFields"
                                    :sort-by.sync="sortKey"
                                    :sort-desc.sync="sortDesc"
                                    :per-page="perPage"
                                    :current-page="currentPage">
                        </b-table>
                    </b-row>
                    <b-row>
                        <b-col cols="12">
                            <b-pagination :total-rows="searchResults.length" :per-page="perPage" v-model="currentPage"/>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-card>
                    <b-row>
                        <b-col cols="8">
                        </b-col>
                        <b-col cols="4">
                            <b-form-group
                                    label-for="display-purposes"
                                    label="Selected User:">
                                <b-form-input
                                    id="display-purposes"
                                    size="lg"
                                    readonly
                                    v-model="selectedUser"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col class="text-right">
                            <b-button-group size="sm">
                                <b-button class="mr-2" variant="dark" @click="getPdf">Generate PDF</b-button>
                                <b-button variant="info" @click="clearForm">Clear</b-button>
                            </b-button-group>
                        </b-col>
                    </b-row>

                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { Component, Vue } from 'vue-property-decorator';
import _ from 'underscore';
import UbcId from '@/components/shared/UbcId.vue';
import download from '@/util/downloader';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import {sprintf} from 'sprintf-js';
import {errorModalOptions} from "../../../util/formatters";


@Component({
    components: {
        UbcId
    }
})
export default class TraineeTranscript extends Vue {

    reportTargetUser = {};

    isSearching = false;

    sortKey = null;
    sortDesc = false;
    currPage = 1;
    perPage = 10;


    get tableFields() {
        return [{
            key: 'ubcId',
            label: 'UBC ID',
            sortable: true
        }, {
            key: 'firstName',
            label: 'First Name',
            sortable: true
        }, {
            key: 'lastName',
            label: 'Last Name',
            sortable: true
        }, {
            key: 'securityLevel',
            label: 'Role',
            sortable: true
        }, {
            key: 'status',
            label: 'Status',
            sortable: true
        }, {
            key: 'organization.name',
            label: 'Organization',
            sortable: true
        }];
    }

    get selectedUser() {
        const user = this.reportTargetUser;
        if (user.firstName === undefined) {
            return undefined;
        } else {
            return user.firstName + " " + user.lastName;
        }
    }

    get searching() { return this.isSearching; }

    get currentPage() {
        if (this.currPage > this.pages) {
            return this.pages;
        } else {
            return this.currPage;
        }
    }

    set currentPage(num) {
        const page = parseInt(num, 10);
        switch (true) {
            case _.isNaN(page):
            case 0 >= page:
                this.currPage = 1;
                break;
            case page > this.pages:
                this.currPage = this.pages;
                break;
            default:
                this.currPage = page;
        }
    }

    get pages() {
        return Math.ceil(this.searchResults.length / this.perPage) || 1;
    }

    get criteria() {
        return this.$store.getters['users/searchCriteria'];
    }

    onRowSelected(selected) {
        const user = selected.pop();
        this.reportTargetUser = user ? user : {};
    }

    clearForm() {
        this.criteria.clear();
        this.reportTargetUser = {};
        this.$store.commit('users/setUsers', []);
    }

    get searchResults() {
        const users = this.$store.getters['users/users'];
        const sortedRows = _.chain(users)
            .sortBy((u) => {
                switch (this.sortKey) {
                    case 'ubcId': return u.ubcId || "";
                    case 'firstName': return u.firstName;
                    case 'lastName': return u.lastName;
                    case 'role': return u.securityLevel.toString();
                    case 'status': return u.status;
                    case 'organization.name': return u.organization.name || "";
                    default: return u.lastName;
                }
            })
            .value();
        return this.sortDesc ? sortedRows.reverse() : sortedRows;
    }

    get fields() {
        return fields;
    }

    validateSearch() {
        if (!this.criteria.lastname && !this.criteria.ubcId) {
            this.$bvModal.msgBoxOk('Please enter at least one search criteria.', {
                size: 'sm',
                buttonSize: 'sm'
            });
            return false;
        }
        return true;
    }

    async doSearch() {
        if (!this.validateSearch()) {
            return;
        }
        this.isSearching = true;
        const criteria = this.criteria;
        criteria.role = { role: 'Trainee' };
        try {
            const {limited, size} = await this.$store.dispatch('users/search', criteria.serialize());
            this.currentPage = 1;
            this.isSearching = false;
            if (limited) {
                this.$bvToast.toast(sprintf('The search results have been limited to %d users. Please choose more specific search criteria.', size), {
                    variant: 'danger',
                    title: 'Results Truncated',
                    solid: true
                });
            }
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    async getPdf() {
        if (!this.isReady()) {
            return;
        }
        try {
            await download('POST', '/api/v1/report/trainee-transcript', JSON.stringify({
                userProfileId: this.reportTargetUser.id
            }));
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    isReady() {
        if (this.reportTargetUser.id === undefined) {
            this.$bvModal.msgBoxOk('A Trainee must be selected.', {
                size: 'sm',
                buttonSize: 'sm'
            });
            return false;
        }
        return true;
    }

    mounted() {
        this.$store.commit('addBreadcrumb', Breadcrumb.create('Trainee Transcript', null, true));
        this.clearForm();
    }

    async beforeDestroy() {
        this.criteria.clear();
        this.$store.commit('users/setUsers', []);
    }

}
</script>


<style scoped>

</style>